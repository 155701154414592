import { Colors } from "style/color";

export const chatSidebarColors = {
    dark:{
        //new chat side bar colors
        chatSideBarBg: Colors.BLACK_SHADE,
        chatSideBarInternalBg: Colors.CINDER,
        SideBarAgentTextColor: Colors.AMOUR_WHITE,
        SideBarAgentHoverBg: Colors.SHIP_GREY,
        SideBarHrBg: Colors.DARK_JUNGLE_GREEN,
        ChatSearchBorderColor: Colors.VAMPIRE_GREY,
        ChatSearchTextColor: Colors.WHITE,
        chatSettingSelectionButtonBg: Colors.CINDER,
        OptionsListHoverBg: Colors.DARK_JUNGLE_GREEN,
        PinnedButtonBg: Colors.BRIGHT_BLUE,
        MenuOptionTextColor: Colors.WHITE,
        ChatListHoverColor: Colors.BALTIC_SEA,
        ChatListHoverBorderColor:Colors.BLACK_EEL_GREY,
        ChatSideBgColor: Colors.DARK_JUNGLE_GREEN,
        chatSideBarInternalBorderColor:Colors.AGENTS_BORDER_COLOR,
        ChatInputBorderColor:Colors.BLUE_CHALK,
        PageTabContainerBoxShadow:Colors.PAGE_TAB_SHADOW_COLOR,
        PageTabContainerBorderColor:Colors.PAGE_TAB_Border_COLOR,
        chatSideBarBorderColor:Colors.CHAT_SIDEBAR_HEADER_BORDER_COLOR,
        WORKER_STATUS_BACKGROUND: Colors.DARK_GREY_NEW,
        CHAT_LIST_BOX_SHADOW_COLOR: Colors.CHAT_LIST_DARK_BOX_SHADOW_COLOR,
        SettingSelectionBorderBg: Colors.CHARCOAL_SHIP_GREY,
        SettingBgColor: Colors.BALTIC_SEA,
        HrTagBackground: Colors.CHARCOAL_SHIP_GREY,
        SidebarPageSelect: Colors.BALTIC_SEA,
        ChatSearchPlaceholderColor: Colors.WHITE,
        OptionListTextColor: Colors.OPTION_LIST_DARK_TEXT_COLOR,
        ParameterDetailTextColor:Colors.WHITE,
        ParameterDetailSpanColor:Colors.WHITE,
        ParameterDetailBorderColor:Colors.PAGE_TAB_Border_COLOR,
        DebugBtnColor: Colors.DEBUG_BTN_COLOR,
        DebugBtnOffBorderColor:Colors.SILVER_CHALICE,
        DebugBtnBorderColor: Colors.AMOUR_WHITE,
        DebugOnBtnColor: Colors.AMOUR_WHITE,
        QuestionsListCollapseIconColorOnHover: Colors.GREY,
        QuestionsListCollapseIconColor: Colors.DEBUG_BTN_COLOR,
        SideabraBgColor: Colors.CINDER,
        SideBarAgentActiveBg: Colors.BALTIC_SEA,
        SidebarAgentTextColor: Colors.AMOUR_WHITE,
        ThreadQuestionHistoryBorderColor: "#ffffff2b",
    },
    light:{
        //new chat side bar colors
        chatSideBarBg: Colors.LIGHT_ICE_BLUE,
        chatSideBarInternalBg: Colors.LIGHT_SEASHELL_BLUE,
        SideBarAgentTextColor: Colors.DARK_CHARCOAL,
        SideBarAgentHoverBg: Colors.ALICE_BLUE,
        SideBarHrBg: Colors.BABY_BLUE,
        ChatInputBgColor: Colors.WHITE,
        ChatSearchBorderColor: Colors.AQUA_BLUE,
        ChatSearchTextColor: Colors.BLACK,
        chatSettingSelectionButtonBg: Colors.WHITE,
        OptionsListHoverBg: Colors.SEASHELL_BLUE,
        PinnedButtonBg: Colors.TROPICAL_BLUE,
        MenuOptionTextColor: Colors.COMET,
        ChatListHoverColor: Colors.PALE_BLUE,
        ChatListHoverBorderColor: Colors.CORAL_BLUE,
        SidebarPageSelect: Colors.CHALK_BLUE,
        SideBarPageIcon: Colors.TROPICAL_BLUE,
        HrTagBackground: Colors.LIGHT_GREY,
        ChatSideBgColor: Colors.WHITE,
        chatSideBarInternalBorderColor: Colors.AGENTS_LIGHT_BORDER_COLOR,
        PageTabContainerBoxShadow: Colors.PAGE_TAB_SHADOW_LIGHT_COLOR,
        PageTabContainerBorderColor: Colors.TRANSPARENT,
        ChatInputBorderColor: Colors. CHAT_INPUT_BORDER_COLOR,
        chatSideBarBorderColor: Colors.TRANSPARENT,
        WORKER_STATUS_BACKGROUND: Colors.LIGHT_SEASHELL_BLUE,
        CHAT_LIST_BOX_SHADOW_COLOR: Colors.CHAT_LIST_BOX_SHADOW_COLOR,
        SettingSelectionBorderBg: Colors.WHITE,
        SettingBgColor: Colors.WHITE,
        ChatSearchPlaceholderColor: Colors.PLACEHOLDER_COLOR,
        OptionListTextColor: Colors.OPTION_LIST_TEXT_COLOR,
        ParameterDetailTextColor:Colors.PARAMETER_DETAIL_TEXT_COLOR,
        ParameterDetailSpanColor:Colors.COMET,
        ParameterDetailBorderColor:Colors.PARAMETER_DETAIL_BORDER_COLOR,
        DebugBtnColor: Colors.BLACK,
        QuestionsListCollapseIconColorOnHover: Colors.ALICE_BLUE,
        QuestionsListCollapseIconColor: Colors.WHITE,
        DebugOnBtnColor: Colors.AZUL,
        DebugBtnBorderColor: Colors.LIGHT_SEASHELL_BLUE,
        DebugBtnOffBorderColor:Colors.GRAY, 
        SideabraBgColor: Colors.TRANSPARENT,
        SideBarAgentActiveBg: Colors.CHALK_BLUE,
        SidebarAgentTextColor: Colors.OPTION_LIST_TEXT_COLOR,
        ThreadQuestionHistoryBorderColor: "#dedede9c",
    }
}