import { useEffect } from "react";
import { updateSettings } from "huma-workflow-react-package";
import { useTypedSelector } from "./use-typed-selector";
import { getAccessToken, getRefreshToken } from "makeRequest";

export const useSetupHumaWorkflowLib = () => {
  const authenticated = useTypedSelector(({ auth }) => auth.user.authenticated);
  const theme = useTypedSelector(({ auth }) => auth.user.settings.theme);

  useEffect(() => {
    updateSettings({
      theme: theme,
    });
  }, [theme]);

  useEffect(() => {
    const setupAuth = async () => {
      try {
        updateSettings({
          auth: {
            getAccessToken: getAccessToken,
            getRefreshToken: getRefreshToken
          },
        });
      } catch (error) {
        console.error("Error setting up auth:", error);
      }
    };

    if (authenticated) {
      setupAuth();
    }
  }, [authenticated]);

};
