import { Dispatch } from "redux";
import { ActionType } from "state/action-types";
import { updateMessageDataAction } from "./ChatEventActions";
import { Message, ResponseComponent } from "types/message";
import { getMessageResponse } from "graphQL/queries";
import { generateClient, GraphQLQuery } from 'aws-amplify/api';
import { getRefreshToken } from "makeRequest";
import { alert } from "./customRouter";
const client = generateClient({ 
  authMode: 'userPool', 
  headers: async () => ({
    'refresh_token': await getRefreshToken()
  }) 
});

export const toggleShowLogs = (messageId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.TOGGLE_MESSAGE_LOGS_VISIBILITY,
      payload: messageId,
    });
  };
};

export const toggleMessageCompare = (message: Message) => {
  return async (dispatch: any,getState:any) => {
    dispatch({
      type: ActionType.TOGGLE_MESSAGE_COMPARE,
      payload: message.id,
    });
    const compare_prior_answer_version_number = (!!message?.is_compare)
      ? (message?.response?.response_versions.length - 1)
      : message?.response?.response_component?.answer_version_number > 1
        ? message?.response?.response_component?.answer_version_number - 2
        : (message?.response?.compare_response_component?.answer_version_number || 1) - 1;
    const compare_current_answer_version_number = message?.response?.response_component?.answer_version_number - 1;
    await dispatch(slideAnswerVersion({...message,is_compare:!message?.is_compare}, message?.response?.parent_message_details?.id, message?.response?.response_versions[compare_prior_answer_version_number]))
    const updated_message = getState().chat.messages.find((msg:Message)=>msg.id === message.id);
    dispatch(slideAnswerVersion({...message,response:updated_message?.response,is_compare:!message?.is_compare}, message?.response?.parent_message_details?.id, message?.response?.response_versions[compare_current_answer_version_number], true))
  };
};

export const slideAnswerVersion = (
  message: Message,
  questionId: string,
  answerId: string,
  isCompareMode?: boolean
) => {
  return async (dispatch: any) => {
    try {
      // check if hydrated answer version is available 
      // if not then fetch the answer version from the server
      const answer_component = message?.response?.response_component;
      const compare_answer_component = message?.response?.compare_response_component;
      const all_answer_components:ResponseComponent[] = [ 
        ...(message?.response?.answers||[]),
        ...(compare_answer_component?[compare_answer_component]:[]),
        answer_component,
      ]

      const slide_answer_location = isCompareMode? "compare_response_component" :"response_component";
      const current_answer_version = message?.response?.[slide_answer_location];

      const hydrated_answer = all_answer_components?.find((answer:ResponseComponent) => answer?.id === answerId)
      // hydrating current answer version

      message.response.answers = [
        ...message.response.answers?.filter((answer)=>answer.id !== hydrated_answer?.id)||[],
        // hydrating only if compare answer component and current answer component are not same
        // (required) otherwise it will be hydrated two times and make conflicts
        ...((!compare_answer_component|| compare_answer_component?.id !== answer_component?.id) && current_answer_version
          ?[current_answer_version]
          :[])
      ];


      if (hydrated_answer) {
        // updating the new answer version
        message.response = {
          ...message.response,
          [slide_answer_location]: {...hydrated_answer, loading: false},
        };
      } else {

        const subPayload = {
          message_id: message?.id,
          question_version_id: questionId,
          answer_version_id: answerId,
        };
        // updating the loading state
        if(message.response && current_answer_version) {
          message.response[slide_answer_location] = {...current_answer_version, loading: true};
          dispatch(updateMessageDataAction(message));
        }
        const result = await client.graphql<GraphQLQuery<{ getMessageResponse: Message}>>({
          query: getMessageResponse,
          variables: subPayload
        });
        if(result?.data?.getMessageResponse){
          // updating the new answer version
          message.response = {
            ...message.response,
            [slide_answer_location]: result.data.getMessageResponse?.response?.response_component,
          };
        }
      }
      dispatch(updateMessageDataAction(message));
      return message;
    } catch (error: any) {
      console.log(error)
      if(error?.name === "NoValidAuthTokens"){
        dispatch(alert("Your session has expired. Please log in again to continue."));
      }else{
        dispatch(alert("Something went wrong while sliding answer version"));
      }
    }
    return false;
  };
};
