import { Dispatch } from "redux";
// import { API, graphqlOperation } from "aws-amplify";
import { generateClient } from 'aws-amplify/api';

import { alert } from "./customRouter";
import { eventReceiver } from "graphQL/subscriptions";
import { Event, EventType, EventTypes } from "types/eventReciever";
import { GraphQLSubscription } from '@aws-amplify/api';
import { ActionType } from "state/action-types";
import { EventHandler as HumaEventHandler } from "huma-workflow-react-package";
import { chatAgents, Command } from "types/chat";
import { setActiveAgent, setSources } from "./AgentActions";
import { refetchChatConfiguration } from "./ChatConfigurationAction";
import { RootState } from "state";
import { messageBlocked, messageInitializing, parseMetadata, subMessageProcessing, threadCreated, threadDeleted, threadJoined, threadRenamed } from "./ChatEventActions";
import { getRefreshToken } from "makeRequest";

const client = generateClient({ 
  authMode: 'userPool', 
  headers: async () => ({
    'refresh_token': await getRefreshToken()
  }) 
});
const showAlert = (event:Event) => {
  return async (dispatch: Dispatch<any>,getState:()=>any) => {
    const error = ["Failed","Failure","Error","Exception"];
    if(event.user_id.includes(getState().auth.user.user_id) && event.content.message){
      dispatch(alert(`${event.content.message}`, {position:"bottom-left"}, error.includes(event.content.type)?"error":"success"));
    }
  }
}
export const updateKnowledgeBaseStatus = (event:Event) => {
  return async (dispatch: Dispatch<any>,getState:()=>any) => {
    HumaEventHandler.emit(event.event_type as any,event);
    const agents = getState().agent.agents;
    const metadata = parseMetadata(event.content.metadata);
    const active_agent = getState().agent.selected_agent;
    const sources = getState().agent.sources;
    const updated_agents = agents.map((agent:chatAgents)=>{
      agent.config.dataSources = agent.config.dataSources?.map((source:Command)=>{        
        if(source.id===metadata.name){
          return {
            ...source,
            status: metadata.status,
            display: metadata?.displayName,
          }
        }
        return source;
      })
      if(active_agent?.agent_name==agent.agent_name){
        dispatch(setActiveAgent(agent))
      }
      return agent;
    })

    const updated_sources = sources.map((source:Command)=>{
      if(source.id===metadata.name){
        return {
          ...source,
          status: metadata.status,
          display: metadata?.displayName,
        }
      }
      return source;
    })
    dispatch({
      type: ActionType.REFETCH_CHAT_CONFIG,
      payload: updated_agents
    });
    dispatch(setSources(updated_sources));
    dispatch(showAlert(event));
  }
}

export const updateCustomerNotices = (event:Event) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const currentNotice = getState().siteSettings?.customer_notice;
    const metadata = parseMetadata(event.content?.metadata);
    const newNotice = metadata?.customer_notice;
    if (newNotice !== currentNotice) {
      dispatch({
        type: ActionType.UPDATE_CUSTOMER_NOTICE,
        payload: newNotice
      });
      dispatch({
        type: ActionType.UPDATE_SHOW_CUSTOMER_NOTICE,
        payload: true
      })
      dispatch({
        type: ActionType.UPDATE_CUSTOMER_NOTICE_DISMISSED,
        payload: false
      })

      dispatch(showAlert(event));
    }
  }
}

export const updateBatchPayload = (event:Event) => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      const metadata = parseMetadata(event.content?.metadata);
      const batch_payloads = getState().runTest.payloads;
      const batch_payload_index = batch_payloads.findIndex((payload) => payload.id === metadata?.id);

      if (batch_payload_index !== -1) {
        // update existing batch payload
        const updated_batch_payloads = [...batch_payloads];
        updated_batch_payloads[batch_payload_index] = {
          ...updated_batch_payloads[batch_payload_index],
          ...metadata
        };
        dispatch({
          type: ActionType.SET_QUICK_LINK_TEST_PAYLOADS_DATA,
          payload: updated_batch_payloads
        });
      } else {
        // add new batch payload
        dispatch({
          type: ActionType.SET_QUICK_LINK_TEST_PAYLOADS_DATA,
          payload: [metadata, ...batch_payloads]
        });
      }
    } catch (error) {
      console.log("error", error);
      dispatch(alert("Error updating batch status"));
    }
  }
}

export const updateBatchRun = (event:Event) => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      const metadata = parseMetadata(event.content?.metadata);
      const runs = getState().runTest.runs;
      const runIndex = runs.findIndex((run) => run.id === metadata?.id);

      if (runIndex !== -1) {
        // Update existing run
        const updatedRuns = [...runs];
        updatedRuns[runIndex] = {
          ...updatedRuns[runIndex],
          ...metadata
        };
        dispatch({
          type: ActionType.SET_RUN_TEST_QUICK_LINKS_DATA,
          payload: updatedRuns
        });
      } else {
        const searchParams = new URLSearchParams(window.location.search);
        const batch_id = searchParams.get('batch_id') || '';

        if(batch_id&& metadata.test_payload_id && metadata.test_payload_id == batch_id){
          // Add new run
          dispatch({
            type: ActionType.SET_RUN_TEST_QUICK_LINKS_DATA,
            payload: [metadata, ...runs]
          });
        }
      }
    } catch (error) {
      console.log("error", error);
      dispatch(alert("Error updating batch runs status"));
    }
  }
}

export const updateBatchRunDetail = (event:Event) => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      const metadata = parseMetadata(event.content?.metadata);
      const runs_detail = getState().runTest.run_detail;
      const run_detail_index = runs_detail.findIndex((run_detail) => run_detail.id === metadata?.id);

      if (run_detail_index !== -1) {
        // Update existing run detail
        const updated_runs_detail = [...runs_detail];
        updated_runs_detail[run_detail_index] = {
          ...updated_runs_detail[run_detail_index],
          ...metadata
        };
        dispatch({
          type: ActionType.UPDATE_RUN_TEST_RUN_DETAILS,
          payload: updated_runs_detail
        });
      } else {
        const searchParams = new URLSearchParams(window.location.search);
        const batch_id = searchParams.get('batch_id') || '';
        const run_id = searchParams.get('run_id') || '';

        if(batch_id&& metadata.test_payload_id && metadata.test_payload_id == batch_id){
          if(run_id && metadata.test_run_id && metadata.test_run_id == run_id){
            // Add new run detail
            dispatch({
              type: ActionType.UPDATE_RUN_TEST_RUN_DETAILS,
              payload: [metadata, ...runs_detail]
            });
          }
        }
      }
    } catch (error) {
      console.log("error", error);
      dispatch(alert("Error updating batch run details status"));
    }
  }
}

// chat event handlers
const chatEventHandlers: Record<EventTypes, (event: Event) => any>  = {
  [EventType.THREAD_CREATED] : threadCreated,
  [EventType.THREAD_RENAMED] : threadRenamed,
  [EventType.THREAD_CLOSED] : threadDeleted,
  [EventType.THREAD_JOINED] : threadJoined,
  [EventType.THREAD_MESSAGE_INITIALIZING] : messageInitializing,
  [EventType.THREAD_MESSAGE_BLOCKED] : messageBlocked,
  [EventType.THREAD_MESSAGE_PROCESSING] : subMessageProcessing,
  [EventType.THREAD_MESSAGE_DONE] : subMessageProcessing,
  [EventType.THREAD_MESSAGE_FAILED] : subMessageProcessing,
  [EventType.THREAD_MESSAGE_SUB_MESSAGE_INITIALIZING] : subMessageProcessing,
  [EventType.THREAD_MESSAGE_SUB_MESSAGE_PROCESSING] : subMessageProcessing,
  [EventType.THREAD_MESSAGE_SUB_MESSAGE_DONE] : subMessageProcessing,
  [EventType.THREAD_MESSAGE_SUB_MESSAGE_FAILURE] : subMessageProcessing,
  [EventType.THREAD_MESSAGE_SUB_MESSAGE_RETRY] : subMessageProcessing,
  [EventType.KNOWLEDGE_BASE_STATUS_UPDATE] : updateKnowledgeBaseStatus,
  [EventType.CHAT_CONFIG_MODIFIED] : refetchChatConfiguration,
  [EventType.CUSTOMER_NOTICE_UPDATED] : updateCustomerNotices,
  [EventType.BATCH_PAYLOAD_UPDATED] : updateBatchPayload,
  [EventType.BATCH_RUN_UPDATED] : updateBatchRun,
  [EventType.BATCH_DETAIL_UPDATED] : updateBatchRunDetail,
};

export const dispatchEvent = (eventType:EventTypes, eventData:Event) => {
  return async (dispatch: Dispatch<any>) => {
    try {
    const chatEventhandler = chatEventHandlers[eventType];
    const workflowEventTypes = [ 'workflow' , 'knowledge_base']
    const isWorkflowEvent = workflowEventTypes.some(type => eventType.startsWith(type));
    if (chatEventhandler) {
      dispatch(chatEventhandler(eventData));
    } else if (isWorkflowEvent) {
      HumaEventHandler.emit(eventType as any,eventData);
      dispatch(showAlert(eventData));
    } else {
      dispatch(subMessageProcessing(eventData));
      console.warn(`No handler found for event type: ${eventType}`);
    }
    } catch (error) {
      dispatch(alert("Error updating data from subscription event"));
    }
  }
};


export const subscribeEventRecieverAction = () => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      const setupSubscription = async () => {
        const user_id = getState().auth.user.user_id;
        const subscription = getState().chat.subscription.eventReceiver;

        // Return early if there's already an active subscription
        if (subscription && !subscription.closed || !getState().auth.signedIn) {
          return;
        }
        if(subscription){
          subscription.unsubscribe();
        }

        const sub = client.graphql<GraphQLSubscription<{eventReceiver: Event}>>(
          {
            query: eventReceiver,
            variables: { user_id }
          }
              ).subscribe({
                next: async (response) => {
                  try {
                    const event = response.data?.eventReceiver;
                    if (event) {
                      console.log("[event from eventReceiver]", event);
                      dispatch(dispatchEvent(event.event_type, event));
                    }
                  } catch (error) {
                    console.error("Error processing event:", error);
                    dispatch(alert("Error processing event"));
                  }
                },
                error: (error) => {
                  console.error("Subscription error:", error);
                  dispatch(alert("Subscription error occurred"));
                  // Optionally attempt to reconnect here
                },
                complete: () => {
                  console.log("Subscription completed");
                  // Optionally attempt to reconnect here
                }
              });

              dispatch(setSubscriptionAction({ name: "eventReceiver", value: sub }));
              return sub
         };

      // Initial subscription setup
      await setupSubscription();

      // Optional: Return cleanup function
      return () => {
        const subscription = getState().chat.subscription.eventReceiver;
        if (subscription) {
          subscription.unsubscribe();
        }
      };

    } catch (error: any) {
      if(error?.name === "NoValidAuthTokens"){
        dispatch(alert("Your session has expired. Please log in again to continue."));
      }else{
        console.error("Subscription setup error:", error);
        dispatch(alert("Could not subscribe to event receiver"));
      }
    }
  };
};

interface SubscriptionResult {
  success: boolean;
  subscription?: any;
  error?: unknown;
}

export const subscribeEventRecieverAction_New = () => {
  return async (dispatch: Dispatch<any>, getState: () => any): Promise<SubscriptionResult> => {
    try {
      const user_id = getState().auth.user.user_id;
      const subscription = getState().chat.subscription.eventReceiver;

      // Return early if there's already an active subscription
      if (subscription && !subscription.closed || !getState().auth.signedIn) {
        return { success: true, subscription };
      }
      
      if(subscription) {
        subscription.unsubscribe();
      }

      const sub = client.graphql<GraphQLSubscription<{eventReceiver: Event}>>(
        {
          query: eventReceiver,
          variables: { user_id }
        }
      ).subscribe({
        next: async (response) => {
          // ... existing next handler ...
          try {
            const event = response.data?.eventReceiver;
            if (event) {
              console.log("[event from eventReceiver]", event);
              dispatch(dispatchEvent(event.event_type, event));
            }
          } catch (error) {
            console.error("Error processing event:", error);
            dispatch(alert("Error processing event"));
          }
          
        },
        error: (error) => {
          console.error("Subscription error:", error);
          dispatch(alert("Subscription error occurred"));
        },
        complete: () => {
          console.log("Subscription completed");
        }
      });

      console.log("subbb" , sub);

      dispatch(setSubscriptionAction({ name: "eventReceiver", value: sub }));
      return { success: true, subscription: sub };

    } catch (error: any) {
      if(error?.name === "NoValidAuthTokens"){
        dispatch(alert("Your session has expired. Please log in again to continue."));
      }else{
        console.error("Subscription setup error:", error);
        dispatch(alert("Could not subscribe to event receiver"));
      }
      return { success: false, error };
    }
  };
};

export const setSubscriptionAction = (sub:{name:string, value: any}) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ActionType.SET_SUBSCRIPTION,
      payload: sub
    })
  }
}