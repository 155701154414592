import { Category, GroupSuggestion, Question, QuickLinks, quickLinkSuggestions, UserContextTab } from "types/runTestTypes";

export const checkGroupSelectedAll = (suggestion: GroupSuggestion): boolean => {
  return suggestion.questions.every(question => !!question?.is_selected);
}
export const categorySelectedAll = (category: Category): boolean => {
    if(category.category_type == "single"){
        return (category?.suggestions as Question[])?.every((suggestion:Question) => suggestion.is_selected);
    } else {
        return (category?.suggestions as GroupSuggestion[])?.every((suggestion: GroupSuggestion) => checkGroupSelectedAll(suggestion as GroupSuggestion));
    }
}
export const flatten = (data: QuickLinks[]): (quickLinkSuggestions | Question)[] => {
  return data.reduce((acc: (quickLinkSuggestions | Question)[], category) => {
    if (category.category_type === "single") {
      // For single categories, all suggestions are of type quickLinkSuggestions
      return [...acc, ...(category.suggestions as quickLinkSuggestions[])];
    } else {
      // For group categories, we need to extract questions from each group
      const groupQuestions = (category.suggestions as GroupSuggestion[]).flatMap(group => group.questions);
      return [...acc, ...groupQuestions];
    }
  }, []);
};

export const persistTabsFlatten = (data: UserContextTab[]): (quickLinkSuggestions | Question)[] => {
  return data.reduce((acc: (quickLinkSuggestions | Question)[], tab) => {
    if (!tab.quickLinks) return acc;

    return [...acc, ...tab.quickLinks.reduce((categoryAcc: (quickLinkSuggestions | Question)[], category) => {
      if (category.category_type === "single") {
        return [...categoryAcc, ...(category.suggestions as quickLinkSuggestions[])];
      } else {
        return [...categoryAcc, ...(category.suggestions as GroupSuggestion[]).flatMap(group => group.questions)];
      }
    }, [])];
  }, []);
};

export const createPayload = (user_context_tabs: UserContextTab[], selected_tab_id: string, quickLinks: QuickLinks[]) => {
  const updatedQuickLinks = user_context_tabs.map(tab => {
      if (tab.id === selected_tab_id) {
          return {
              ...tab,
              quickLinks: quickLinks
          };
      }
      return tab;
  });

  const payload = updatedQuickLinks.reduce((acc: any[], tab) => {
      if (tab.selectAllChecked || tab.id === selected_tab_id || !tab.selectAllChecked) {
          const tabPayload = {
              tab_id: tab.id,
              categories: tab.quickLinks?.reduce((catAcc: any[], category) => {
                  const categoryPayload = {
                      id: category.id,
                      category_type: category.category_type,
                      questions: [] as string[]
                  };

                  if (category.suggestions) {
                      category.suggestions.forEach((suggestion: any) => {
                          if (suggestion.is_selected) {
                              if (category.category_type === 'question-set') {
                                  // For question-set categories, add only the suggestion id
                                  categoryPayload.questions.push(suggestion.id);
                              } else if (suggestion.questions) {
                                  // For group categories
                                  suggestion.questions.forEach((question: any) => {
                                      if (question.is_selected) {
                                          categoryPayload.questions.push(question.id);
                                      }
                                  });
                              } else {
                                  // For single question categories
                                  categoryPayload.questions.push(suggestion.id);
                              }
                          }
                      });
                  }

                  if (categoryPayload.questions.length > 0) {
                      catAcc.push(categoryPayload);
                  }

                  return catAcc;
              }, [])
          };

          if (tabPayload.categories && tabPayload.categories.length > 0) {
              acc.push(tabPayload);
          }
      }
      return acc;
  }, []);
    // Collect all selected question IDs
    const selectedQuestionIds = payload.flatMap(tab =>
        tab.categories.flatMap((category: any) => category.questions)
    );
  return { payload, selectedQuestionIds };
};


const colorsList = ['#4287f5', '#f54242', '#5e4037', '#8d6e63', '#4242f5', '#004d41', '#78919c', '#f5a503', '#0c648f', '#00897b'];

export  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colorsList.length);
    return colorsList[randomIndex];
};

export const getColorForEmail = (email: string) => {
    // Generate a simple hash of the email string
    const hash = email.split('').reduce((acc, char) => {
        return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);

    // Use the hash to get a consistent index
    const index = Math.abs(hash) % colorsList.length;
    return colorsList[index];
};

export const DURATION_OPTIONS = [
    { label: 'Past 24 hours', value: 1 },
    { label: 'Past 7 days', value: 7 },
    { label: 'Past 30 days', value: 30 }
  ];