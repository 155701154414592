// This is used to close the chat mutation.

export const closeChat = `
  mutation MyMutation($chat_id: String!) {
    closeChat(chat_id: $chat_id) {
      created_at
      user_id
      event_metadata {
        event_data {
          answer_id
          created_at
          id
          is_visually_hidden
          question_id
          question_version_number
          recepient
          message_metadata {
            answer_version_number
            focus
            message_intent
            sources
            processing_status {
              reason
              state
            }
          }
          sub_message_metadata {
            answer_version_number
            id
            readout_title
            processing_status {
              reason
              state
            }
            delta {
              created_at
              delta
              delta_data_type
              delta_type
              updated_at
            }
          }
        }
        event_message
      }
      event_type
      id
      thread_metadata {
        access_scope
        agent
        created_at
        id
        is_chat_processed
        is_closed_chat
        is_pinned_chat
        topic
        users {
          chat_role
          email
          name
          user_id
        }
      }
      author {
        metadata
        role
        tool
      }
      content {
        message
        metadata
        type
      }
    }
  }
`;
