// import { gql } from "@apollo/client";

// This blockMessage is used to block the chat on the basis of MessageId

export const blockMessage = `
  mutation MyMutation($answer_id: String!, $chat_id: String!, $message_id: String!, $question_id: String!) {
    blockMessage(message_id: $message_id, answer_id: $answer_id, chat_id: $chat_id, question_id: $question_id) {
      event_type
      id
      created_at
      user_id
      event_metadata {
        event_message
        event_data {
          id
          question_id
          answer_id
          question_version_number
          is_visually_hidden
          created_at
          recepient
          message_metadata {
            focus
            sources
            processing_status {
              state
              reason
            }
            user_message {
              author {
                role
                tool
                metadata
              }
             content {
              type
              message
              metadata
             }
             metadata
            }
            message_intent
            answer_version_number
          }
          sub_message_metadata {
            id
            delta {
              delta_data_type
              delta_type
              delta
              created_at
              updated_at
            }
            processing_status {
              state
              reason
            }
            readout_title
          }
        }
      }
      author {
        role
        tool
        metadata
      }
      thread_metadata {
        id
        topic
        users {
          user_id
          name
          email
          chat_role
        }
        agent
        created_at
        access_scope
        is_pinned_chat
        is_closed_chat
        is_chat_processed
      }
    }
  }
`;