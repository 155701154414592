import { Dispatch } from "redux";
import { Event } from "types/eventReciever";
import { setLatestMessageParameters, updateChatThreads } from "./ChatActions";
import { ActionType } from "state/action-types";
import { alert, push } from "./customRouter";
import { chatThread } from "types/chatv2";
import { DebugLog, DebugLogTypes, debugLogTypes, Delta, Fragment, Message, ResponseComponent } from "types/message";
import { MessageVersion } from "types/message";

export const threadCreated = (event: Event) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const chats = getState().chat.chats;
    if (event?.thread_metadata?.id) {
      const new_chat = event.thread_metadata;
      if (chats.find((chat: chatThread) => chat.id === new_chat.id)) return;
      dispatch(updateChatThreads([new_chat, ...chats]));
      // cleaning up previous messages
      // dispatch(resetChatThreadAction());
      // dispatch(push(`/?chat_id=${new_chat.id}`));
    }
  };
};

export const threadRenamed = (event: Event) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    if (event?.thread_metadata?.id) {
      const url_params = new URLSearchParams(window.location.search);
      const chat_id = url_params.get("chat_id");
      const current_chat = getState().chat.current_thread_details || {
        id: chat_id,
      };
      if (current_chat?.id == event?.thread_metadata?.id) {
        dispatch({
          type: ActionType.SET_CURRENT_CHAT_THREAD,
          payload: event.thread_metadata,
        });
      }
      const updatedChats = getState().chat.chats.map((item: any) => {
        if (item.id == event?.thread_metadata?.id) {
          return event.thread_metadata;
        }
        return item;
      });
      dispatch(updateChatThreads(updatedChats));
    }
  };
};

export const threadDeleted = (event: Event) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    if (event?.thread_metadata?.id) {
      const url_params = new URLSearchParams(window.location.search);
      const chat_id = url_params.get("chat_id");
      const current_chat = getState().chat.current_thread_details || {
        id: chat_id,
      };
      if (current_chat?.id == event?.thread_metadata?.id) {
        dispatch(push("/chat"));
      }
      const filterRecords = getState().chat.chats.filter(
        (item: any) => item.id !== event?.thread_metadata?.id
      );
      dispatch(updateChatThreads(filterRecords));
    }
  };
};

export const threadJoined = (event: Event) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const chats = getState().chat.chats;
    if (event?.thread_metadata?.id) {
      const new_chat = event.thread_metadata;
      if (chats.find((chat: chatThread) => chat.id === new_chat.id)) return;
      dispatch(updateChatThreads([new_chat, ...chats]));
      dispatch(push(`/chat?chat_id=${new_chat.id}`));
    }
  };
};

export const messageInitializing = (event: Event) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      const url_params = new URLSearchParams(window.location.search);
      const chat_id = url_params.get("chat_id");
      const is_new_chat = url_params.get("is_new_chat");
      if (is_new_chat) {
        window.history.replaceState({}, "", `/chat?chat_id=${chat_id}`);
      }
      const current_chat = getState().chat.current_thread_details || {
        id: chat_id,
      };
      if (current_chat?.id && event?.thread_metadata?.id === current_chat?.id) {
        const messages = getState().chat.messages;
        const event_message_data = event?.event_metadata?.event_data;
        const sender = JSON.parse(event?.author?.metadata)?.user_details;

        const answer_version = {
          id: event_message_data?.answer_id || "",
          answer_version_number:
            event_message_data?.message_metadata?.answer_version_number || 1,
          processing_status: event_message_data?.message_metadata
            ?.processing_status || { state: "initializing" },
          feedback: {
            text: null,
            reaction: null,
          },
          metadata: "",
          fragments: [],
        };

        const message_version: MessageVersion = {
          id: event_message_data?.question_id,
          created_at: event_message_data?.created_at || 0,
          message_intent:
            event_message_data?.message_metadata?.message_intent || "question",
          question_version_number:
            event_message_data?.question_version_number || 1,
          user_message:
            event_message_data?.message_metadata?.user_message || {},
          sender: sender || {},
        };

        const response = {
          id: event_message_data?.question_id,
          parent_message_details: message_version,
          response_versions: [event_message_data?.answer_id],
          response_component: answer_version,
        };

        let new_message: Message = {
          id: event_message_data?.id,
          created_at: event_message_data?.created_at || 0,
          focus: event_message_data?.message_metadata?.focus,
          sources: event_message_data?.message_metadata?.sources,
          response: response,
          message_versions: [message_version],
          sender: sender || {},
          showLogs: true,
        };

        // checking if the message already exists in the messages array
        const existing_message: Message | null = messages.find(
          (msg: Message) => msg.id === new_message?.id
        );
        if (existing_message) {
          // Todo: checking if the question version already exists in the message versions array
          
          const new_answer_id = new_message?.response?.response_component?.id;
          new_message = {
            ...existing_message,
            response:{
              ...new_message.response,
              ...existing_message.response,
              response_component: new_message.response.response_component,
              response_versions:[
                ...existing_message.response.response_versions.filter((answer_id: string) => answer_id !== new_answer_id),
                new_answer_id
              ],
              answers:[
                ...(existing_message?.response?.answers?.filter((answer_id: ResponseComponent) => answer_id?.id !== existing_message?.response?.response_component?.id && answer_id?.id !== new_answer_id) || []),
                ...((new_answer_id !== existing_message?.response?.response_component?.id)
                    ?[existing_message?.response?.response_component]
                    :[]),
              ],
            }
          }
        }

        const its_last_message = messages.findIndex(
          (msg: Message) => msg.id === new_message?.id
        )  === messages.length - 1;

        if(its_last_message){
          // updating selected agent, focus and sources message parameters to match the latest message
          dispatch(
            setLatestMessageParameters(event?.thread_metadata?.agent, [
              new_message,
            ])
          );
        }
        // adding the message in the messages array
        dispatch(updateMessageDataAction(new_message));
      }
      return true;
    } catch (error) {
      console.log("error adding message to chat", error);
      dispatch(alert("Error adding message to chat"));
    }
    return false;
  };
};

export const messageBlocked = (event: Event) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      const url_params = new URLSearchParams(window.location.search);
      const chat_id = url_params.get("chat_id");
      const current_chat = getState().chat.current_thread_details || {
        id: chat_id,
      };
      if (current_chat?.id && event?.thread_metadata?.id === current_chat?.id) {
        dispatch(subMessageProcessing(event));
        const messages = getState().chat.messages;
        const event_message_data = event?.event_metadata?.event_data;
        const message: Message | null =
          messages?.length &&
          messages?.find((msg: Message) => msg.id === event_message_data?.id);
        if (message) {
          // adding the message in the messages array
          if(message?.response?.response_component?.id === event_message_data?.answer_id){
            message.response.response_component.fragments =
              message.response.response_component.fragments.map(
              (fragment: Fragment) => {
                fragment.processing_status.state = "blocked";
                return fragment;
              }
            );
          } else {
            if(message?.response?.answers?.length){
              const hydrated_answer = message.response.answers.find(
                (answer_id: ResponseComponent) => answer_id?.id === event_message_data?.answer_id
              );
              if(hydrated_answer){
                hydrated_answer.fragments = 
                  hydrated_answer.fragments.map((fragment:Fragment)=>{
                    fragment.processing_status.state = "blocked";
                    return fragment;
                  });
                  message.response.answers = [
                    hydrated_answer,
                    ...message.response.answers.filter((answer_id: ResponseComponent) => answer_id?.id !== hydrated_answer?.id)
                  ];
              }
            }
          }
          dispatch(updateMessageDataAction(message));
        }
      }
    } catch (error) {
      console.log("error adding message to chat", error);
      dispatch(alert("Error adding message to chat"));
    }
  };
};

export const subMessageProcessing = (event: Event) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      const url_params = new URLSearchParams(window.location.search);
      const chat_id = url_params.get("chat_id");
      const current_chat = getState().chat.current_thread_details || {
        id: chat_id,
      };
      // checking if the event is for the current chat
      if (current_chat?.id && event?.thread_metadata?.id === current_chat?.id) {
        const messages = getState().chat.messages;
        const event_message_data = event?.event_metadata?.event_data;
        const message: Message | null =
          messages?.length &&
          messages?.find((msg: Message) => msg.id === event_message_data?.id);
        const message_status =
          event_message_data.message_metadata.processing_status;
        if (message) {
          // we can have two data stores for the same message's question and answer versions in redux
          // one for the current versions and one for all other hydrated versions of both question and answer versions
          // below we are updating the event data for both storages using if else condition matching id of the current message version

          if (event_message_data?.question_id === message?.response.id) {
            // update is for current question version, we will now check for current answer version
            if (
              !message.response?.response_component?.id &&
              !message.response?.answers?.length
            ) {
              const sender = JSON.parse(event?.author?.metadata)?.user_details;

              const answer_version = {
                id: event_message_data?.answer_id || "",
                answer_version_number:
                  event_message_data?.message_metadata?.answer_version_number ||
                  1,
                processing_status: event_message_data?.message_metadata
                  ?.processing_status || { state: "initializing" },
                feedback: {
                  text: null,
                  reaction: null,
                },
                metadata: "",
                fragments: [],
              };

              const message_version: MessageVersion = {
                id: event_message_data?.question_id,
                created_at: event_message_data?.created_at || 0,
                message_intent:
                  event_message_data?.message_metadata?.message_intent ||
                  "question",
                question_version_number:
                  event_message_data?.question_version_number || 1,
                user_message:
                  event_message_data?.message_metadata?.user_message || {},
                sender: sender || {},
              };

              const response = {
                id: event_message_data?.question_id,
                parent_message_details: message_version,
                response_versions: [event_message_data?.answer_id],
                response_component: answer_version,
              };

              message.response = response;
              message.response.response_component.fragments =
                updateFragmentsEvent(event, []);
            } else if (
              message.response.response_component.id ===
              event_message_data?.answer_id
            ) {
              // update is for current answer version, as well as current question version
              // updating
              message.response.response_component.processing_status =
                message_status;
              message.response.response_component.fragments =
                updateFragmentsEvent(
                  event,
                  message.response.response_component.fragments
                );
            }
          } else {
            // for question versioning

            // when the update is (not) for current question version
            // check for hydrated question versions if available we update the hydrated question version
            // const find_message_version = message.message_versions?.find(
            //   (mv: MessageVersion) => mv.id === event_message_data?.question_id
            // );
            // if (find_message_version && find_message_version.response) {
            //   // hydrated question version found
            //   // hydrated question version's also have stores for hydrated answer versions and current answer version

            //   if (
            //     find_message_version.response.response_component.id ===
            //     event_message_data?.answer_id
            //   ) {
            //     // event is for current answer version, inside the hydrated question version
            //     // updating
            //     find_message_version.response.response_component.processing_status =
            //       message_status;
            //     find_message_version.response.response_component.fragments =
            //       updateFragmentsEvent(
            //         event,
            //         find_message_version.response.response_component.fragments
            //       );
            //     message.message_versions = [
            //       find_message_version,
            //       ...message.message_versions.filter(
            //         (mv: MessageVersion) => mv.id !== find_message_version.id
            //       ),
            //     ];
            //   } else {
            //     // update is (not) for current answer version, but hydrated question version
            //     // check for hydrated answer versions if available we update the hydrated answer version

            //     if (
            //       find_message_version.response?.answers &&
            //       find_message_version.response?.answers?.length
            //     ) {
            //       const existing_answer =
            //         find_message_version.response.answers.find(
            //           (answer: ResponseComponent) =>
            //             answer.id === event_message_data?.answer_id
            //         );
            //       if (existing_answer) {
            //         // updating
            //         existing_answer.processing_status = message_status;
            //         existing_answer.fragments = updateFragmentsEvent(
            //           event,
            //           existing_answer.fragments
            //         );
            //         find_message_version.response.answers = [
            //           existing_answer,
            //           ...find_message_version.response.answers.filter(
            //             (answer: ResponseComponent) =>
            //               answer.id !== existing_answer.id
            //           ),
            //         ];
            //         message.message_versions = [
            //           find_message_version,
            //           ...message.message_versions.filter(
            //             (mv: MessageVersion) =>
            //               mv.id !== find_message_version.id
            //           ),
            //         ];
            //       }
            //     }
            //   }
            // }
          }

          if (
            event_message_data.message_metadata.processing_status.state ===
              "done" ||
            event_message_data.message_metadata.processing_status.state ===
              "blocked" ||
            event_message_data.message_metadata.processing_status.state ===
              "failure"
          ) {
            message.showLogs = false; // hide logs when chat is processed completely
          }
          dispatch(updateMessageDataAction(message));
        }
        dispatch(updateThreadDetailsEvent(event));
      }
    } catch (error) {
      console.log("error updating message status", error);
      dispatch(alert("Error updating message status"));
    }
  };
};

export const parseMetadata = (metadata: string) => {
  try {
    return JSON.parse(metadata);
  } catch (error) {
    return metadata;
  }
};

export const updateMessageDataAction = (message:Message) => {
    return async (dispatch: Dispatch<any>,getState:()=>any) => {
      const messages_list = [...getState().chat.messages]
      const old_message_index = messages_list.findIndex((msg:Message) => msg.id === message?.id);
      if (old_message_index !== -1) { // Check if the message was found
        messages_list.splice(old_message_index, 1, message); // Replace the message at the found index
      } else {
        messages_list.unshift(message); // Add the message to the end of the array
      }
      dispatch({
        type: ActionType.SET_CHAT_THREAD_MESSAGES,
        payload: messages_list
      });
    }
  }
  
  // for updating the thread details (example: is_chat_processed etc)
  export const updateThreadDetailsEvent = (event:Event) => {
    return async (dispatch: Dispatch<any>,getState:()=>any) => {
      dispatch({ 
        type: ActionType.SET_CURRENT_CHAT_THREAD,
        payload: {
          ...getState().chat.current_thread_details,
          ...event.thread_metadata
        }
      })
    }
  }
  
  // updates and returns the fragments property of answer version based on the event data received 
  function updateFragmentsEvent(event:Event,fragments:Fragment[]):Fragment[] {
    const event_message_data = event?.event_metadata?.event_data;
    if(!event_message_data?.sub_message_metadata) return fragments;
    // checks if the fragment already exists in the fragments array 
    // if exists update the fragment with the new data and if not create a new fragment in else part.
    const fragment = fragments?.find((fragment:Fragment) => fragment.sub_message_id === event_message_data?.sub_message_metadata?.id);
    if(fragment){
      // updating the fragment title and processing status
      fragment.readout_title = event_message_data?.sub_message_metadata?.readout_title||"";
      fragment.processing_status = event_message_data?.sub_message_metadata?.processing_status||{state:"processing"};
  
      // updating the fragment content and deltas
      // delta from event has two kinds delta itself and delta's debug logs
      // checking if delta already exists in the fragment deltas array than update the delta's debug logs
      const new_delta = event_message_data?.sub_message_metadata?.delta||null;
      
      // updates fragment content based on delta ( in some cases like visual, error , streaming).
      fragment.content = updateFragmentContentEvent(fragment,new_delta);
  
      const existing_delta = fragment.deltas.find((delta:Delta)=>delta.delta_id === new_delta.delta_id);
      if(new_delta&&!existing_delta){
          // if delta does not exists already then adds it.
          fragment.deltas = [ 
            ...fragment?.deltas,
            event_message_data?.sub_message_metadata?.delta
          ];
          
      }
      if (existing_delta && new_delta){
        // delta already exists with same id inside fragment
  
        // checking if new delta is a debug log, if yes then adding it to the delta's debug logs
        if(debugLogTypes.includes(new_delta.delta_type)){
          if(existing_delta){
            const existing_logs = existing_delta.debug_logs||[];
            const new_debug_log:DebugLog = {
              debug_message: new_delta.delta,
              debug_time: new_delta.created_at,
              debug_type: (new_delta.delta_type as DebugLogTypes),
            }
            existing_logs.push(new_debug_log);
            existing_delta.debug_logs = existing_logs;
          }
        }
  
        // updating the delta with new data
        const deltas = fragment.deltas.filter((delta:Delta)=>delta.delta_id !== new_delta.delta_id);
        deltas.push(existing_delta);
        fragment.deltas = deltas;
      }
      // replacing the fragment with the updated fragment
      const fragment_index = fragments?.findIndex((fragment:Fragment) => fragment.sub_message_id === event_message_data?.sub_message_metadata?.id);
      fragments.splice(fragment_index,1,fragment);
      return fragments;
    
    } else {
      // creating a new fragment
      const new_fragment:Fragment = {
        id: event_message_data?.sub_message_metadata?.id,
        created_at: event_message_data?.created_at||0,
        author: event?.author,
        recepient: event_message_data?.recepient,
        content: updateFragmentContentEvent(({content:""} as Fragment),event_message_data?.sub_message_metadata?.delta),
        answer_id: event_message_data?.answer_id||"",
        sub_message_id: event_message_data?.sub_message_metadata?.id,
        readout_title: event_message_data?.sub_message_metadata?.readout_title||"",
        processing_status: event_message_data?.sub_message_metadata?.processing_status||{state:"processing"},
        deltas: [event_message_data?.sub_message_metadata?.delta||{}],
      };
      fragments.push(new_fragment);
      return fragments;
    }
  }
  
  // updates and returns the content property of the fragment based on the delta type received in the event data
  function updateFragmentContentEvent(fragment:Fragment,delta:Delta):string {
    if(delta.delta_type==="visual"){
      return delta.delta;
    } else if (delta.delta_type==="add"){
      return (fragment?.content||"") + delta.delta;
    } else if (delta.delta_type==="replace"){
      return delta.delta;
    } else {
      return fragment.content;
    }
  }