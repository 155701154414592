import React, { useEffect, useState , useRef} from 'react';
import { useActions } from 'hooks/use-actions';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import { Hub } from 'aws-amplify/utils';
import { PubSub } from '@aws-amplify/pubsub';
import { amplifyConfig } from 'config';
import { SmallText, StatusBadge, StatusContainer, StatusWrapper } from './style';

const CONNECTION_CHECK_INTERVAL = 30000;

export type ConnectionStatus = 'connecting' | 'connected' | 'disconnected' | 'pending' | 'error' | 'offline';

const useConnectionState = () => {
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('disconnected');
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isReconnecting, setIsReconnecting] = useState(false);

  const { setSubscriptionAction, alert, subscribeEventRecieverAction } = useActions();
  const eventReceiver = useTypedSelector(({chat}) => chat.subscription.eventReceiver);

  // Handle reconnection logic
  const handleReconnect = async () => {
    // return if internet connection is not connected or working
    if (!isOnline) {
      setConnectionStatus('offline');
      return;
    }

    if (isReconnecting) return;

    setIsReconnecting(true);
    setConnectionStatus('connecting');

    try {
      // Clear existing subscription
      if (eventReceiver) {
        eventReceiver.unsubscribe();
        setSubscriptionAction({ name: "eventReceiver", value: null });
      }

      subscribeEventRecieverAction();
      setConnectionStatus('connected');
      setIsReconnecting(false);
    } catch (error) {
      console.error('Reconnection failed:', error);
      setIsReconnecting(false);
      setConnectionStatus('error');
    }
  };

  // Effect for connection check
  useEffect(() => {
    if (!isOnline) return;

    // let connectionCheckInterval: NodeJS.Timeout;

    const checkConnection = async () => {
      console.log("eventReceiver<<<<<", eventReceiver);
      console.log("isReconnecting<<<<<", isReconnecting);
      console.log("connectionStatus<<<<<", connectionStatus);
      if (!isReconnecting &&
          connectionStatus !== 'connected' &&
          (!eventReceiver || eventReceiver.closed)) {
        await handleReconnect();
      }
    };

    // Initial check
    checkConnection();

    // Set up interval for periodic checks
    const connectionCheckInterval = setInterval(checkConnection, CONNECTION_CHECK_INTERVAL);

    return () => {
      clearInterval(connectionCheckInterval);
    };
  }, [isOnline, connectionStatus, eventReceiver, isReconnecting]);

  // Effect for online/offline status
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      if (connectionStatus === 'offline') {
        setConnectionStatus('pending');
      }
    };

    const handleOffline = () => {
      setIsOnline(false);
      setConnectionStatus('offline');
      alert("Internet connection lost");
      if (eventReceiver) {
        eventReceiver.unsubscribe();
        setSubscriptionAction({ name: "eventReceiver", value: null });
      }
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [connectionStatus]);

  // Effect for PubSub connection state
  useEffect(() => {
    if (!isOnline) return;

    const pubsub = new PubSub({
      region: amplifyConfig.aws_appsync_region,
      endpoint: amplifyConfig.aws_appsync_graphqlEndpoint
    });

    const hubListener = Hub.listen('api', (data: any) => {
      const { payload } = data;
      if (payload.event === CONNECTION_STATE_CHANGE) {
        const state = payload.data.connectionState as ConnectionState;
        
        switch (state) {
          case ConnectionState.Connected:
            setConnectionStatus('connected');
            setIsReconnecting(false);
            break;
          case ConnectionState.ConnectionDisrupted:
            setConnectionStatus('error');
            alert("Something went wrong in connection");
            handleReconnect();
            break;
          case "ConnectedPendingNetwork":
            setConnectionStatus('pending');
            break;
          case ConnectionState.Connecting:
            setConnectionStatus('connecting');
            break;
          default:
            setConnectionStatus('disconnected');
        }
      }
    });

    return () => hubListener();
  }, [isOnline]);

  return { 
    connectionStatus, 
    eventReceiver, 
    isOnline, 
    isReconnecting, 
    handleReconnect 
  };
};

const getStatusMessage = (status: ConnectionStatus): string => {
  switch (status) {
    case 'connecting':
      return 'Establishing connection...';
    case 'connected':
      return 'Connected';
    case 'disconnected':
      return 'Connection lost';
    case 'pending':
      return 'Checking connection...';
    case 'error':
      return 'Connection error';
    case 'offline':
      return 'You seem to be offline. Please check your internet connection to continue';
    default:
      return status;
  }
};

export default function SocketConnection() {
  const { connectionStatus, isReconnecting, handleReconnect } = useConnectionState();

  if (connectionStatus === 'connected') {
    return null;
  }
 
  return (
    <StatusContainer isConnected={connectionStatus === "connected" as ConnectionStatus} connectionStatus={connectionStatus}>
      <StatusWrapper>
        <StatusBadge status={connectionStatus}>
          {getStatusMessage(connectionStatus)}
        </StatusBadge>
        {isReconnecting ? (
          <SmallText>Attempting to reconnect...</SmallText>
        ) : (
          <>
            {(connectionStatus === 'error' || connectionStatus === 'disconnected' || connectionStatus === 'pending' || connectionStatus === 'offline') && (
              <a
                style={{
                  color: "#2563eb",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontSize: '0.75rem'
                }}
                onClick={handleReconnect}
                aria-label="Manually reconnect to socket"
              >
                Try to reconnect
              </a>
            )}
          </>
        )}
      </StatusWrapper>
    </StatusContainer>
  );
}

