import { get, patch, post, Response, delete_req, put } from "makeRequest";
import { BatchRunSchedulePayload, BatchUtteranceActivity, ComparisonResultsResponse, LogsResponse, QuickLinkTestPayload, ReferenceDetailsResponse, RunPayload, RunTestQuickLinks, SchedulerPayload, TabsResponse, TestPayload, TestRunDetail } from "types/runTestTypes";

interface QuickLinkTestPayloadResponse {
  payloads: QuickLinkTestPayload[];
  has_next_page?: boolean;
  total_pages?: number;
  message?: string;
  status?: number;
  error?: string;
}

export interface SubmitTestPayloadResponse {
  status: string
  test_payload: TestPayload
  run_payload: RunPayload
  message?: string
  error?: string
}

interface TestRunDetailsResponse {
  run_id: string;
  run_detail: TestRunDetail[];
  has_next_page?: boolean;
  message?: string;
  status?: number;
  error?: string;
}
interface BatchUtteranceActivityResponse {
  activity: BatchUtteranceActivity[];
  has_next_page?: boolean;
  message?: string;
  status?: number;
  error?: string;
  total_records?: number;
}

export interface BatchRunSchedulerResponse {
  schedule_payload: BatchRunSchedulePayload;
  error?: string;
  message?: string;
  status?: number;
}

export const getQuickLinkTestPayloads = (page: number, limit: number): Response<QuickLinkTestPayloadResponse> => {
 return get(`/quicklink-test-payloads?page=${page}&limit=${limit}`)
}
export const getTestHistoryConfig = (page: number, limit: number, id: string): Response<any> => {
 return get(`/quicklink-test-run?page=${page}&limit=${limit}&payload_id=${id}`)
}

export function fetchRunTestQuickLinks(tab_id: string, action: string, payload_id: string): Response<RunTestQuickLinks> {
  return get(`/quicklinks-categories-payload?tab_id=${tab_id}&action=${action}&payload_id=${payload_id}`);
}

export function getRunTestUserContextTabs(action: string, payload_id: string): Response<TabsResponse> {
 return get(`/quicklinks-tabs-payload?action=${action}&payload_id=${payload_id}`);
}

export function saveTestPayload(payload: any, configName: string, action: string, batch_type: string, useCache? :boolean, max_recursion?: number, duration?: number): Response<SubmitTestPayloadResponse> {
  let requestPayload;
  const exclude_utterances = {exclude_utterances: payload, duration: duration, use_cache: useCache, max_recursion: max_recursion};

  if (batch_type === "activity") {
    requestPayload = {
      batch_type,
      payload: exclude_utterances,
      config_name: configName,
      action
    };
  } else {
    requestPayload = {
      batch_type,
      payload: {
        use_cache: useCache,
        quicklink_selections: payload,
        max_recursion: max_recursion
      },
      config_name: configName,
      action
    };
  }

  return post(`/save-test-payload`, requestPayload);
}

export function saveAndRunTestPayload(payload: any, configName: string, action: string, batch_type: string, useCache?:boolean, max_recursion?: number, duration?: number): Response<SubmitTestPayloadResponse> {
  let requestPayload;
  const exclude_utterances = {exclude_utterances: payload, duration: duration, use_cache: useCache, max_recursion: max_recursion};

  if (batch_type === "activity") {
    requestPayload = {
      batch_type,
      payload: exclude_utterances,
      config_name: configName,
      action
    };
  } else {
    requestPayload = {
      batch_type,
      payload: {
        use_cache: useCache,
        quicklink_selections: payload,
        max_recursion: max_recursion
      },
      config_name: configName,
      action
    };
  }
  return post(`/submit-test-endpoint`, requestPayload);
}

export function runTestPayload(id: string): Response<any> {
  return post(`/run-test-payload?payload`, {payload_id: id});
}

export function getTestRunDetails(id: string, page: number, limit: number): Response<TestRunDetailsResponse> {
  return get(`/quicklink-test-run-details?run_id=${id}&page=${page}&limit=${limit}`);
}

export function getResultChatId(id: string): Response<ComparisonResultsResponse> {
  return get(`/quicklink-test-comparison-results?details_id=${id}`);
}

export function getLockAndApprovalStatus(details_id: string, action: string): Response<{message: string, current_approval_status: string, current_lock_status: string, error: string}> {
  const body = {
    details_id: details_id,
    action: action
  }
  return patch(`/batch-result-update`, body);
}

export function getTestHistoryLogs(details_id: string, action: string): Response<LogsResponse>{
  return get(`/batch-result-action-log?details_id=${details_id}&action=${action}`);
}

export function getUpdateReference(payload_id: string, component_id: string, current_reference_id: string): Response<ReferenceDetailsResponse> {
  return get(`/test-reference-details?payload_id=${payload_id}&component_id=${component_id}&current_reference_id=${current_reference_id}`);
}

export function deletePayload(id: string): Response<{message: string, error: string}> {
  return delete_req(`/delete-payload?payload_id=${id}`);
}

export function deleteDetail(id: string): Response<{message: string, error: string}> {
  return delete_req(`/delete-result-detail?test_details_id=${id}`);
}

export function getBatchUtteranceActivity(duration: number, page: number, limit: number): Response<BatchUtteranceActivityResponse> {
  return get(`/fetch-utterance-activity?duration=${duration}&page=${page}&limit=${limit}`);
}

export function getScheduledActivityBatch(payload_id:string ,duration_value: number,page: number): Response<BatchUtteranceActivityResponse> {
  return get(`/fetch-utterance-activity-payload?payload_id=${payload_id}&duration=${duration_value}&page=${page}&limit=100`);
}

export function createBatchRunScheduler(payload: SchedulerPayload, id: string): Response<any> {
  const requestPayload = {
    ...payload,
    payload_id: id
  }
  return post(`/create-batch-run-schedule`, requestPayload);
}

export function getBatchRunScheduler(payload_id: string): Response<BatchRunSchedulerResponse> {
  return get(`/batch-run-schedule?payload_id=${payload_id}`);
}

export function updateBatchRunScheduler(payload: SchedulerPayload, id: string): Response<any> {
  const requestPayload = {
    ...payload,
    payload_id: id
  }
  return put(`/update-batch-run-schedule`, requestPayload);
}
