import { alert } from "./state/action-creators/customRouter";
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import { Amplify } from 'aws-amplify';

import { PubSub } from '@aws-amplify/pubsub';

import { store as reduxStore } from './state';
import * as serviceWorker from "./serviceWorker";
import { amplifyConfig, authConfig, LOCALSTACK_STATUS } from "config";
import { setSubscriptionAction } from "state/action-creators/EventReceiverActions";
import { Hub } from 'aws-amplify/utils';

export const applyAmplifyHubListener = () => {
  // Listen for connection state changes
  const pubsub = new PubSub({
    region: amplifyConfig.aws_appsync_region,
    endpoint: amplifyConfig.aws_appsync_graphqlEndpoint
  });

  Hub.listen('api', (data: any) => {
    const { payload } = data;
    try {
      if (payload.event === CONNECTION_STATE_CHANGE) {
        payload.data.connectionState as ConnectionState;
      }
      else if (ConnectionState.ConnectionDisrupted == payload.data.connectionState) {
        reduxStore.dispatch(alert("something went wrong in connection"));
      }

      if (payload.data.connectionState == "ConnectedPendingNetwork" || ConnectionState.ConnectionDisrupted == payload.data.connectionState) {
        reduxStore.getState().chat.subscription.eventReceiver?.unsubscribe();
        reduxStore.dispatch(setSubscriptionAction({ name: "eventReceiver", value: null }));
      }
      console.log('[Pubs/subs]', payload.event, payload.data.connectionState);
    } catch (error) {
      console.log(error);
    }
  });
}

export const registerServiceWorker = () => {
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  // serviceWorker.unregister();
  serviceWorker.register({
    onSuccess: () => {
      reduxStore.dispatch({ type: 'SW_INIT' })
    },
    onUpdate: registration => {
      reduxStore.dispatch({ type: 'SW_UPDATE', payload: registration })
    },
  });
  
};

export const setupAmplifyConfig = () => {
  Amplify.configure({
    API: {
      GraphQL: {
        endpoint: amplifyConfig.aws_appsync_graphqlEndpoint,
        region: amplifyConfig.aws_appsync_region,
        defaultAuthMode: 'apiKey',
        // authMode: 'apiKey',
        // defaultAuthMode: amplifyConfig.aws_appsync_authenticationType, // Changed from authenticationType to authMode
        apiKey: amplifyConfig.aws_appsync_apiKey
      }
    },
    Auth: {
      Cognito: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID||"",
        userPoolId: authConfig.userPoolId,
        userPoolClientId: authConfig.userPoolWebClientId,
        signUpVerificationMethod: 'code', // or 'link',
        ...LOCALSTACK_STATUS === 'enabled' ? {
          userPoolEndpoint: "http://localhost:4566",
          identityPoolEndpoint: "http://localhost:4566",
          allowGuestAccess: true,
        } : {}
      }
    },
    Storage: {  // Add this Storage configuration
      S3: {
        bucket: authConfig.bucketName,
        region: amplifyConfig.aws_appsync_region,
        dangerouslyConnectToHttpEndpointForTesting: LOCALSTACK_STATUS === 'enabled' ? "true" : undefined
      }
    }
  });
}

export default function AppSetup() {
    // applyAmplifyHubListener();
    setupAmplifyConfig();
    registerServiceWorker();
}