import styled from "styled-components";
import { ConnectionStatus } from "./SocketConnection";

// Define prop types for styled components
interface StatusContainerProps {
    isConnected: boolean;
    connectionStatus: string
  }
  
  interface StatusBadgeProps {
    status: ConnectionStatus;
  }
  
export const SmallText = styled.span`
  font-size: 0.75rem;
  color: #4b5563;
`;

// Styled components
export const StatusContainer = styled.div<StatusContainerProps>`
  position: fixed;
  top: 0.10rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 50;
  min-width: 250px;
  padding: .75rem 1rem;
  ${props => {
    switch (props.connectionStatus) {
      case 'pending':
        return 'background: #fff7ed; color: #9a3412; border: 1px solid #fdba74;';
      case 'error':
        return 'background: #fef2f2; color: #dc2626; border: 1px solid #fca5a5;';
      case 'offline':
        return 'background: #f9d9d6; color: #dc2626; border: 1px solid #eb6060;';
      case 'connecting':
        return 'background: #f0fdf4; color: #16a34a; border: 1px solid #86efac;';
      default:
        return 'background: #f9fafb; color: #374151; border: 1px solid #e5e7eb;';
    }
  }}
  backdrop-filter: blur(8px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
  display: ${props => props.isConnected ? 'none' : 'block'};
  border-radius: 0.5rem;
`;

// Update the StatusBadge styled component to include the new 'offline' state
export const StatusBadge = styled.span<StatusBadgeProps>`
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
`;